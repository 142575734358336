<template>
  <div class="container">
    <b-row class="justify-content-center">
      <b-col md="6" sm="8" class="p-0 m-0">
        <b-card no-body class="p-0 m-0">
          <b-card-body class="p-4">
            <b-form>
              <h1>我的页面</h1>
              <b-input-group class="mt-4 mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-user"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="名字" v-model="user.username" />
              </b-input-group>
              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-lock"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="password" class="form-control" placeholder="新密码" v-model="user.newPassword" />
              </b-input-group>
              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-lock"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  type="password"
                  class="form-control"
                  placeholder="确认新密码"
                  v-model="user.newPasswordConfirm"
                />
              </b-input-group>
              <b-button
                @click.prevent="$router.push({ path: '/otpVerification' })"
                block
                style="background: #2176fc; color: white"
                >OTP Verification</b-button
              >
              <b-button @click.prevent="$router.go(-1)" variant="secondary" block>前一页</b-button>
              <b-button @click.prevent="updateUser" variant="primary" block>修改</b-button>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import { mapGetters } from 'vuex'

export default {
  name: 'User',
  created() {},
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.remove('bg-white')
    this.getUser()
  },
  computed: {
    ...mapGetters({
      currentUser: 'getUser',
    }),
  },
  data() {
    return {
      user: {
        newPassword: null,
        newPasswordConfirm: null,
      },
    }
  },
  watch: { user() {} },
  methods: {
    async getUser() {
      const response = await UserService.getUser(this.currentUser.id)
      if (response.status == 200) {
        this.user = response.data.user
      }
    },
    async updateUser() {
      if (this.newPassword != this.newPasswordConfirm) {
        alert('您的新密码和确认密码不相符，请再次输入密码。')
        return
      }
      const response = await UserService.updateUser(this.user.id, {
        username: this.user.username,
        newPassword: this.user.newPassword,
      })
      if (response.status == 200) {
        alert('Saved')

        this.user.newPassword = null
        this.user.newPasswordConfirm = null
      }
    },
  },
}
</script>
